import React, { useState } from 'react'
import { AnalyticsProps } from './AnalyticsSteps'
import { useResponse } from '../../Store/Hooks'
import { Editor } from '@monaco-editor/react'

const CustomAnalyticParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {
    const { setWarningResponse } = useResponse()
    const [connectorName, setConnectorName] = useState('')
    const [params, setParams] = useState({
        code: '' as any,
        inputType: 'text',
    })
    const [key, setKey] = React.useState(0)

    const options = {
        readOnly: false,
        minimap: { enabled: false },
    }

    React.useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                setParams({
                    ...params,
                    ..._model.config.params.analytics[stepIndex].params,
                })
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setKey(key + 1)
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const onSubmit = () => {
        if (params.code) {
            if (connectorName.length === 0 || params.code.length === 0) {
                setWarningResponse('Required Field', `Please fill out all of the required fields: ${[
                    'Name',
                    'Executable Code'
                ].join(', ')}`,)
                return
            }
        }
        onSaveChanges(connectorName, { ...params })
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='font-bold text-ai-700'>Agent Analytic</p>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                />
            </div>
            <label className='px-2 pl-0 text-[13px] text-ai'>Executable Code*</label>
            <Editor
                key={key}
                options={options}
                height='300px'
                defaultLanguage='python'
                defaultValue={params.code || '// Place executable python code here'}
                onChange={js => {
                    setParams({ ...params, code: js })
                }}
            />

            <hr className='border-ai-200' />

            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default CustomAnalyticParams
