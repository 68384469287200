import React from 'react'
import DeployedAnalyticsParams from './DeployedAnalyticParams'
import DeployedModelParams from './DeployedModelParams'
import LocalAnalyticsParams from './LocalAnalyticParams'
import LocalModelParams from './LocalModelParams'
import { swapItems, validateRequiredFields } from '../../Utils/utils'
import { useResponse } from '../../Store/Hooks'
import CustomAnalyticParams from './CustomAnalyticParams'
import AgentAnalyticParams from './AgentAnalyticParams'
import { AIRModel, BasicStepsProps } from '../../features/types'
import Dropdown from '../Dropdown'
import { ArrowDownward, ArrowUpward, ContentCopy, Edit, EditOutlined, Remove } from '@mui/icons-material'
import SalesforceConnector from './ReverseMLOpts/SalesforceConnector'
import AwsS3Connector from './ReverseMLOpts/AwsS3Connector'
import RedshiftConnector from './ReverseMLOpts/RedshiftConnector'
import SnowflakeConnector from './ReverseMLOpts/SnowflakeConnector'
import DatabricksConnector from './ReverseMLOpts/DatabricksConnector'
import SftpConnector from './ReverseMLOpts/SftpConnector'

export interface AnalyticsProps {
    _model: AIRModel
    onSaveChanges: (connectorName: string, params: any) => void
    onCancel?: () => void
    stepIndex: number
}

const analyticsOptions = [
    {
        option: 'Custom Analytic',
        className: 'CustomAnalytic',
        required: ['code'],
    },
    {
        option: 'Agent Analytic',
        className: 'AgentAnalytic',
        required: ['code'],
    },
    {
        option: 'Deployed Analytic',
        className: 'DeployedAnalytic',
        required: ['url', 'inputType'],
    },
    {
        option: 'Deployed Model',
        className: 'DeployedModel',
        required: ['url', 'inputType'],
    },
    {
        option: 'Local Analytic',
        className: 'LocalAnalytic',
        required: ['path', 'inputType'],
    },
    {
        option: 'Local Model',
        className: 'LocalModel',
        required: ['path', 'inputType'],
    },
    {
        option: 'AWS S3',
        className: 's3',
        required: ['connectorType', 'column'],
    },
    {
        option: 'Salesforce',
        className: 'salesforce',
        required: ['connectorType', 'column'],
    },
    {
        option: 'Redshift',
        className: 'redshift',
        required: ['connectorType', 'column'],
    },
    {
        option: 'Snowflake',
        className: 'snowflake',
        required: ['connectorType', 'column'],
    },
    {
        option: 'Databricks',
        className: 'databricks',
        required: ['connectorType', 'column'],
    },
    {
        option: 'SFTP',
        className: 'sftp',
        required: ['connectorType', 'column'],
    },
]

const ReverseMLMapping = ['s3', 'salesforce', 'redshift', 'snowflake', 'databricks', 'sftp']

const analyticsClassComponents = {
    CustomAnalytic: CustomAnalyticParams,
    AgentAnalytic: AgentAnalyticParams,
    DeployedAnalytic: DeployedAnalyticsParams,
    DeployedModel: DeployedModelParams,
    LocalAnalytic: LocalAnalyticsParams,
    LocalModel: LocalModelParams,
    s3: AwsS3Connector,
    salesforce: SalesforceConnector,
    redshift: RedshiftConnector,
    snowflake: SnowflakeConnector,
    databricks: DatabricksConnector,
    sftp: SftpConnector,
}

type ObjectKey = keyof typeof analyticsClassComponents

const AnalyticsSteps: React.FC<BasicStepsProps> = ({ _model, onConfigChange }) => {
    const { setWarningResponse } = useResponse()
    const [optionClass, setOptionClass] = React.useState('')
    const [selectedOpt, setSelectedOpt] = React.useState<any>(null)
    const [editIndex, setEditIndex] = React.useState(-1)

    if (_model === null) {
        return null
    }

    const onOptionChange = (event: any) => {
        const selectedIndex = Number(event.target.value)
        setOptionClass(analyticsOptions[selectedIndex].className)
        const selected = analyticsOptions[selectedIndex]
        setSelectedOpt(selected)
    }

    const onSaveChanges = (connectorName: string, params: any) => {
        let required = selectedOpt?.required
        const steps = _model.config.params.analytics ?? []

        if (!required && editIndex > -1) {
            const possibleOption = analyticsOptions.find(opts => opts.className === steps[editIndex].className)
            if (possibleOption) {
                required = possibleOption.required
            }
        }
        if(required) {
            if (!validateRequiredFields(params, required)) {
                setWarningResponse('Required Fields', `Please fill out all of the required fields: ${required.join(', ')}`)
                return
            }
        }

        let config = {
            ..._model.config,
            params: {
                ..._model.config.params,
            },
        }
        let analytics = config.params.analytics ?? []
        if (editIndex > -1) {
            analytics[editIndex].params = params
            analytics[editIndex].label = connectorName
            setEditIndex(-1)
        } else {
            const isReverseMLClass = ReverseMLMapping.indexOf(optionClass) > -1
            analytics.push({ className: isReverseMLClass ? 'ReverseMLWorkflow' : optionClass, params, label: connectorName })
            setOptionClass('')
            setSelectedOpt(null)
            setEditIndex(-1)
        }
        config.params.analytics = analytics
        onConfigChange(config)
    }

    const onCancel = (index: number) => {
        setOptionClass('')
        setSelectedOpt(null)
        if (index === editIndex) {
            setEditIndex(-1)
        }
    }

    const onRemove = (index: number) => {
        let steps = _model.config.params.analytics ? [..._model.config.params.analytics] : []
        steps.splice(index, 1)
        let config = { ..._model.config }
        config.params.analytics = steps
        onConfigChange(config)
        if (index === editIndex) {
            setEditIndex(-1)
        }
    }

    const renderParamsComponent = () => {
        const Component = analyticsClassComponents[optionClass as ObjectKey]
        if (Component) {
            return (
                <Component
                    _model={_model}
                    onSaveChanges={onSaveChanges}
                    stepIndex={editIndex}
                    onCancel={() => {
                        setSelectedOpt(null)
                        setOptionClass('')
                        setEditIndex(-1)
                    }}
                />
            )
        }
        return null
    }

    const onCloneStep = (index: number) => {
        let steps = _model.config.params.analytics ? [..._model.config.params.analytics] : []
        let step = { ...steps[index] }
        steps = [...steps, step]
        let config = { ..._model.config }
        config.params.analytics = steps
        onConfigChange(config)
    }

    const onStepSwap = (index: number, withIndex: number) => {
        let m = { ..._model }
        if (m.config.params.analytics) {
            let steps = swapItems(m.config.params.analytics, index, withIndex)
            m.config.params.analytics = steps
            onConfigChange(m.config)
        }
    }

    const renderEditParamsComponent = (index: number) => {
        if (_model.config.params.analytics && editIndex > -1) {
            let className = _model.config.params.analytics[editIndex].className
            if(className === 'ReverseMLWorkflow') {
                className = _model.config.params.analytics[editIndex].params.connectorType
            }
            const Component = analyticsClassComponents[className as ObjectKey]
            if (Component) {
                return (
                    <Component
                        _model={_model}
                        stepIndex={editIndex}
                        onSaveChanges={onSaveChanges}
                        onCancel={() => {
                            setSelectedOpt(null)
                            setOptionClass('')
                            setEditIndex(-1)
                            onCancel(index)
                        }}
                    />
                )
            }
        }
        return null
    }

    const analytics = _model.config.params.analytics ?? []
    const selectedClassIndex = optionClass.length > 0 ? analyticsOptions.findIndex(a => a.className === optionClass) : -1

    return (
        <div>
            <p className='mb-2 text-[16px] font-bold text-ai-700'>Analytic class</p>
            <Dropdown
                selectedIndex={selectedClassIndex}
                placeholder='Choose analytic class'
                items={analyticsOptions.map(opt => opt.option) as string[]}
                handleDropdownChange={onOptionChange}
                size='w-1/2'
            />

            <div className='my-6'>{renderParamsComponent()}</div>

            <div>
                <table aria-label='Rendering steps' className='w-full border border-gray-300'>
                    <thead className='border-b border-b-gray-300 bg-white text-left'>
                        <tr>
                            <th className='w-1 px-4 py-2'>
                                <span className='text-[16px] text-ai-800 '>#</span>
                            </th>
                            <th>
                                <span className='text-[16px] text-ai-800 '>Analytic Class</span>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className='text-left'>
                        {analytics.length === 0 && (
                            <tr>
                                <td colSpan={7}>
                                    <div className='w-full p-6 text-center font-[16px] text-ai-800'>There are no analytic step yet.</div>
                                </td>
                            </tr>
                        )}
                        {analytics.map((step: any, index: number) => {
                            return (
                                <React.Fragment key={step.className + index}>
                                    <tr className={index % 2 === 0 ? 'bg-ai-200' : ''}>
                                        <td className='p-4'>
                                            <span className='text-ai-800 '>{index + 1 + '.'}</span>
                                        </td>

                                        <td>
                                            <span className='text-ai-800 '>{step.label?.length > 0 ? `${step.label}` : `${step.className} ${step.params.connectorType ?? ''}`}</span>
                                        </td>
                                        <td>
                                            {index !== 0 && (
                                                <button
                                                    className='btn-icon text-ai'
                                                    onClick={() => {
                                                        onStepSwap(index, index - 1)
                                                    }}
                                                >
                                                    <ArrowUpward fontSize='small' />
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            {index !== analytics.length - 1 && (
                                                <button
                                                    className='btn-icon text-ai'
                                                    onClick={() => {
                                                        onStepSwap(index, index + 1)
                                                    }}
                                                >
                                                    <ArrowDownward fontSize='small' />
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            <button
                                                className='btn-icon'
                                                onClick={() => {
                                                    setSelectedOpt(null)
                                                    setOptionClass('')
                                                    if (editIndex === index) {
                                                        setEditIndex(-1)
                                                    } else {
                                                        setEditIndex(index)
                                                    }
                                                }}
                                            >
                                                <EditOutlined fontSize='small' />
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                className='btn-icon'
                                                onClick={() => {
                                                    onCloneStep(index)
                                                }}
                                            >
                                                <ContentCopy fontSize='small' />
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                className='btn-icon'
                                                onClick={() => {
                                                    onRemove(index)
                                                }}
                                            >
                                                <Remove color='error' />
                                            </button>
                                        </td>
                                    </tr>
                                    {editIndex > -1 && editIndex === index && (
                                        <tr key={step.className + index + '-edit-view'}>
                                            <td colSpan={7}>
                                                <div className='rounded bg-white p-3'>{renderEditParamsComponent(index)}</div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AnalyticsSteps
