import React from "react"
import BinaryClassificationClass from "./BinaryClassificationClass"
import MulticlassClassificationClass from "./MulticlassClassificationClass"
import ObjectDetectionClass from "./ObjectDetectionClass"
import RegressionClass from "./RegressionClass"
import { validateRequiredFields } from "../../Utils/utils"
import { useResponse } from "../../Store/Hooks"
import { AIRModel, BasicStepsProps } from "../../features/types"
import Dropdown from "../Dropdown"

export interface PostprocessingProps {
    _model: AIRModel,
    onSaveChanges: (params: any) => void,
    onRemoveClass: () => void
}

const postprocessingOptions = [
    {
        option: "Binary Classification",
        className: "BinaryClassification",
        required: ['labelMap', 'threshold']
    },
    {
        option: "Multiclass Classification",
        className: "MulticlassClassification",
        required: ['labelMap']
    },
    {
        option: "Object Detection",
        className: "ObjectDetection",
        required: ['labelMap', 'threshold']
    },
    {
        option: "Regression",
        className: "Regression",
        required: ['min', 'max']
    }
]

const postprocessingClassComponents = {
    BinaryClassification: BinaryClassificationClass,
    MulticlassClassification: MulticlassClassificationClass,
    ObjectDetection: ObjectDetectionClass,
    Regression: RegressionClass,
}

type ObjectKey = keyof typeof postprocessingClassComponents;

const PostprocessingSteps: React.FC<BasicStepsProps> = ({ _model, onConfigChange }) => {

    const { setWarningResponse } = useResponse()
    const [optionClass, setOptionClass] = React.useState('')
    const [selectedOpt, setSelectedOpt] = React.useState<any>(null)

    
    React.useEffect(() => {
        setOptionClass('')
        if (_model && _model.config.params.postprocessingSteps && _model.config?.params?.postprocessingSteps?.length > 0) {
            setOptionClass(_model.config?.params?.postprocessingSteps[0].className)
            const selected = (postprocessingOptions.filter((a) => {
                if( _model.config.params.postprocessingSteps) {
                    return a.className === _model.config?.params?.postprocessingSteps[0].className
                }
            }))[0]
            setSelectedOpt(selected)
        }
    }, [_model])
    
    if (_model === null) {
        return null
    }

    const onOptionChange = (event: any) => {
        const selectedIndex = Number(event.target.value)
        setOptionClass(postprocessingOptions[selectedIndex].className)
        const selected = postprocessingOptions[selectedIndex]
        setSelectedOpt(selected)
    }

    const onSaveChanges = (params: any) => {
        if (optionClass !== null) {
            let postprocessingSteps = [{ className: optionClass, params }]
            let config = {
                ..._model.config,
                params: {
                    ..._model.config.params,
                    postprocessingSteps
                }
            }
            if (validateRequiredFields(params, selectedOpt.required)) {
                onConfigChange(config)
            } else {
                setWarningResponse('Required Fields', `Please fill out all of the required fields: ${selectedOpt.required.join(', ')}`)
            }
        }
    }

    const onRemoveClass = () => {
        const config = {
            ..._model.config,
            params: {
                ..._model.config.params,
                harvestingSteps: null
            }
        }
        if(onConfigChange) {
            onConfigChange(config)
        }
        setOptionClass('')
        setSelectedOpt(null)
    }

    const renderParamsComponent = () => {
        const Component = postprocessingClassComponents[optionClass as ObjectKey]
        if (Component) {
            return <Component _model={_model} onSaveChanges={onSaveChanges} onRemoveClass={onRemoveClass} />
        }
        return null
    }

    const selectedClassIndex = optionClass.length > 0 ? postprocessingOptions.findIndex((a) => a.className === optionClass) : -1

    return (
        <div>
            <p className="text-ai-700 text-[16px] font-bold mb-2">Postprocessing class</p>
            <Dropdown selectedIndex={selectedClassIndex} placeholder="Choose postprocessing class" items={postprocessingOptions.map((opt) => opt.option) as string[]} handleDropdownChange={onOptionChange} size='w-1/2'/>
            <div className="my-6">
                {
                    renderParamsComponent()
                }
            </div>
        </div>
    )
}

export default PostprocessingSteps