import React from "react";
import { RenderingProps } from "./RenderingSteps";
import StyleParams from "./StyleParams";
import { useResponse } from "../../Store/Hooks";

const alignments = [
    {
        id: 0,
        label: "Relative",
        value: "none",
    },
    {
        id: 1,
        label: "Horizontal",
        value: "row",
    },
    {
        id: 2,
        label: "Vertical",
        value: "column",
    },
];

const ContainerRenderingParams: React.FC<RenderingProps> = ({_model, stepIndex, onSaveChanges, onCancel }) => {

    const { setWarningResponse } = useResponse()
    const [params, setParams] = React.useState<any>({
        id: "",
        querySelector: "body",
        position: "static",
        staticPosition: "append",
        display: "",
        orientation: "",
        yOffset: "",
        xOffset: "",
        height: "",
        width: "",
        style: {
            container: {
                color: "black",
                margin: "0",
                padding: "24px",
                fontSize: "16px",
                background: "white",
                borderRadius: "10px"
            }
        }
    });

    React.useEffect(() => {
        if (stepIndex > -1 && _model.config.params.renderingSteps) {
            setParams({...params, ..._model.config.params.renderingSteps[stepIndex].params} );
        }
    }, [stepIndex]);

    if (!_model) {
        return null;
    }

    const handlePositionChange = (opt: any) => {
        setParams({
            ...params,
            [opt.target.name]: opt.target.value,
        });
    };

    const handleElementPositioning = (opt: any) => {
        let _params = { ...params };
        if (opt.value !== "none") {
            _params.display = "flex";
            _params.orientation = opt.value;
        } else {
            _params.display = null;
            _params.orientation = opt.value;
        }
        setParams({ ..._params });
    };

    const handleFieldsOnChange = (event: any) => {
        setParams({ ...params, [event.target.name]: event.target.value });
    };

    const handleStyleChange = (element: any, attribute: any, value: string | null) => {
        // Fixes issues with read-only errors
        let _p: any = {
            ...params,
            style: {
                ...params.style,
                [element]: {
                    ...params.style[element as keyof typeof params.style]
                }
            }
        }
        if(value !== null) {
            _p.style[element][attribute] = value
        } else {
            delete _p.style[element][attribute]
        }
        setParams(_p)
    }

    const onSubmit = () => {
        let _params = { ...params };
        if(_params.id.length === 0 ||
            _params.querySelector.length === 0 ||
            _params.position.length === 0 ||
            _params.orientation.length === 0 ||
            _params.height.length === 0 ||
            _params.width.length === 0) {
                setWarningResponse('Required Fields', `Please fill out all of the required fields: ${["ID", "Query selector", "Position", "Height", "Width"].join(', ')}`)   
                return
        }
        if(_params.position === 'static' && _params.staticPosition.length === 0 ) {
            setWarningResponse('Required Fields', `Please select if container will be injected before or after content`)   
            return
        } else if(_params.position === 'absolute' && (_params.xOffset.length === 0 || _params.yOffset.length === 0)) {
            setWarningResponse('Required Fields', `If overlay positioning selected, please fill out the following fields: ${["X Offset", "Y Offset"].join(', ')}`)   
            return
        }
        if (_params.display === null) {
            delete _params.display
            delete _params.orientation
        }
        onSaveChanges(_params);
    };

    return (
        <div className="flex flex-col gap-4">
            <p className="font-bold text-ai-700">
                Container Rendering
            </p>
            <div className="flex flex-col gap-2">
                <p className="block text-[13px] text-ai">Container position</p>
                <div className="flex gap-5">
                    <div className="relative flex items-center">
                        <input
                            className="radio-parent peer"
                            name="position"
                            type="radio"
                            value="static"
                            checked={params.position === "static"}
                            onChange={handlePositionChange}
                        />
                        <div className="radio" />
                        <label htmlFor="" className="pl-3 truncate">
                            Static
                        </label>
                    </div>
                    <div className="relative flex items-center">
                        <input
                            className="radio-parent peer"
                            name="position"
                            type="radio"
                            value="absolute"
                            checked={params.position === "absolute"}
                            onChange={handlePositionChange}
                        />
                        <div className="radio" />
                        <label htmlFor="" className="pl-3 truncate">
                            Overlay (Relative to content)
                        </label>
                    </div>
                </div>
            </div>
            {params.position === "static" && (
                <div className="flex flex-col gap-2">
                    <p className="block text-[13px] text-ai">Insert container</p>
                    <div className="flex gap-5">
                        <div className="relative flex items-center">
                            <input
                                className="radio-parent peer"
                                name="staticPosition"
                                type="radio"
                                value="prepend"
                                checked={params.staticPosition === "prepend"}
                                onChange={handlePositionChange}
                            />
                            <div className="radio" />
                            <label className="pl-3 truncate">
                                Before content (prepend)
                            </label>
                        </div>
                        <div className="relative flex items-center">
                            <input
                                className="radio-parent peer"
                                name="staticPosition"
                                type="radio"
                                value="append"
                                checked={params.staticPosition === "append"}
                                onChange={handlePositionChange}
                            />
                            <div className="radio" />
                            <label className="pl-3 truncate">
                                After content (append)
                            </label>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex flex-col gap-2">
                <p className="block text-[13px] text-ai">Container elements positioning</p>
                <div className="flex gap-5">
                    {alignments.map((opt, index) => {
                        return (
                            <div key={opt.value ?? "block" + index + "alignments"} className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="orientation"
                                    type="radio"
                                    value={opt.value ?? ""}
                                    checked={params.orientation === opt.value}
                                    onChange={handleElementPositioning.bind(this, opt)}
                                />
                                <div className="radio" />
                                <label htmlFor="" className="pl-3 truncate">
                                    {opt.label}
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>
            <hr className="border-ai-200" />
            <p className="font-bold text-ai-700">
                Identifiers
            </p>
            <div>
                <label htmlFor="container-id" className="block px-2 text-[13px] text-ai">Container ID</label>
                <input id="container-id" className="input w-full" onChange={handleFieldsOnChange} value={params.id ?? ''}  placeholder="container-id" name="id" />
            </div>
            <div>
                <label htmlFor="query-selector" className="block px-2 text-[13px] text-ai">Query Selector</label>
                <input id='query-selector' className="input w-full" onChange={handleFieldsOnChange} value={params.querySelector ?? ''}  placeholder="body" name="querySelector" />
                <label htmlFor="query-selector" className="block px-2 text-[13px] text-ai-800">Container is injected at the body of the content by default. Use query selectors to inject in specific elements.</label>
            </div>
            <hr className="border-ai-200" />
            <p className="font-bold text-ai-700">
                Dimension
            </p>
            <div className='flex gap-2'>
                <div className="flex-1">
                    <label htmlFor="height" className="block px-2 text-[13px] text-ai">Height</label>
                    <input id="height" className="input w-full" onChange={handleFieldsOnChange} value={params.height ?? ''}  placeholder="10" name="height" />
                    <label htmlFor="height" className="block px-2 text-[13px] text-ai-800">Number is represented in %</label>
                </div>
                <div className="flex-1">
                    <label htmlFor="width" className="block px-2 text-[13px] text-ai">Width</label>
                    <input id='width' className="input w-full" onChange={handleFieldsOnChange} value={params.width ?? ''}  placeholder="10" name="width" />
                    <label htmlFor="width" className="block px-2 text-[13px] text-ai-800">Number is represented in %</label>
                </div>
            </div>
            {params.position !== 'static' && 
                <>
                    <hr className="border-ai-200" />
                    <p className="font-bold text-ai-700">
                        Coordinates
                    </p>
                    <div className='flex gap-2'>
                        <div className="flex-1">
                            <label htmlFor="x-offset" className="block px-2 text-[13px] text-ai">X Offset</label>
                            <input id="x-offset" className="input w-full" onChange={handleFieldsOnChange} value={params.xOffset ?? ''}  placeholder="10" name="xOffset" />
                            <label htmlFor="x-offset" className="block px-2 text-[13px] text-ai-800">Number is represented in %</label>
                        </div>
                        <div className="flex-1">
                            <label htmlFor="y-offset" className="block px-2 text-[13px] text-ai">Y Offset</label>
                            <input id='y-offset' className="input w-full" onChange={handleFieldsOnChange} value={params.yOffset ?? ''}  placeholder="10" name="yOffset" />
                            <label htmlFor="y-offset" className="block px-2 text-[13px] text-ai-800">Number is represented in %</label>
                        </div>
                    </div>
                </>
            }
            <hr className="border-ai-200"/>
            <p className="text-ai-700 font-bold">Styling</p>
            <div className="w-full bg-ai-50 flex">
                <div className="flex-1">
                    <StyleParams style={params.style} onStyleChange={handleStyleChange}/>
                </div>
                <div className="flex-1 w-4/5 bg-ai-100 p-2 flex flex-col gap-2">
                    <p className="text-ai-700 font-bold">Preview</p>
                    <hr className="border-ai-200"/>
                    <div className="flex-1 flex flex-col justify-center gap-2 ">
                        <div style={{...params.style.container as any}}>
                            <p>Hello container</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
};

export default ContainerRenderingParams;
